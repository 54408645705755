import Inputmask from 'inputmask'

window.SERVICE_FORM_MIXIN = {
  data: () => ({
    fresh: true,
    success: false,
  }),
  computed: {
    sectionElement () { // i'm so sorry
      return this.$refs.form.closest('.page-section')
    },
    ajaxData () {
      return this.model
    },
    disabled () {
      return !this.fresh && this.$v.$invalid
    },
    cafeSelectData () {
      return this.addresses.map((obj) => ({
        ...obj,
        label: obj.name + ' ' + obj.address
      }))
    },
  },
  watch: {
    success (isSuccess) {
      if (!isSuccess) return
      if (this.gaFormName) ga('send', 'event', this.gaFormName, 'Отправлена')
      if (this.yaReachGoal) yaCounter2341183.reachGoal(this.yaReachGoal)
      // if (this.gaFormName) ga('send', 'event', this.gaFormName, 'Отправлена')


      this.sectionElement.querySelector('h3').style.display = 'none'
      this.sectionElement.querySelector('.service-download-grid').style.display = 'none'
    },
  },
  methods: {
    invalid (prop) {
      return !this.fresh && this.$v.model[prop]?.$invalid ? 'is-error' : ''
    },
    ajax () {
      axios
        .post(this.ajaxUrl, { data: this.ajaxData })
        .then(() => (this.success = true))
    },
    submit (ev) {
      ev.preventDefault()

      this.fresh = false
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.ajax()
      }
    },
    getAddresses () {
      axios
        .get('/local/ajax/getCafeList.php')
        .then(({ data }) => {
          const temp = data.map((addr) => ({
            ...addr,
            sortname: addr.name
              .replace('Кафе на ', '')
              .replace('Кафе во ', '')
              .replace('Кафе в ', '')
          }))

          temp.sort((x, y) => {
            if (x.sortname < y.sortname) {
              return -1
            }

            if (x.sortname > y.sortname) {
              return 1
            }

            return 0
          })

          this.addresses = temp
        })
    },
  },
  mounted () {
    Inputmask().mask(this.$refs.form.querySelectorAll('input'))

    if (this.addresses) {
      this.getAddresses()
    }

    if (this.init) {
      this.init()
    }
  },
}

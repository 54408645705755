
document.addEventListener('DOMContentLoaded', function() {
  // let yaCounter2341183 = window.yaCounter2341183
let dayjs=window.dayjs
let dayjs_plugin_calendar=window.dayjs_plugin_calendar
let dayjs_plugin_isSameOrAfter=window.dayjs_plugin_isSameOrAfter
let dayjs_plugin_isSameOrBefore=window.dayjs_plugin_isSameOrBefore
dayjs.locale('ru');
dayjs.extend(dayjs_plugin_calendar);
dayjs.extend(dayjs_plugin_isSameOrAfter);
dayjs.extend(dayjs_plugin_isSameOrBefore);
})

// Пропуск кадров для события "resize"
import ResizeThrottler from './common/resizeThrottler'
// Набор точек останова ширины экрана
import breakpoints from './common/breakpoints'

import Glightbox from 'glightbox/src/js/glightbox'
import './common/lightbox'
import './common/modal'

import './mixins/service-form'
import './mixins/media-mix'
import './mixins/modal-mix'

import { validationMixin } from 'vuelidate'
import * as validators from 'vuelidate/lib/validators'

window.$ = $
window.VALI_MIXIN = validationMixin
window.VALI_LIB = validators

window.convertEscapedString2Json = (str) => {
  if (str && str.length > 50) { // some magic for old addresses
    const t = new DOMParser().parseFromString(str, 'text/html') // black magic
    return JSON.parse(t.documentElement.textContent) // avada kedavra!
  }

  return {}
}

window.debounce = function (func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args) }, timeout)
  }
}

const glightbox = Glightbox()

let pathname = window.location.pathname;
/*
if(pathname == '/cafe/list/'){

if($(window).width()>768){


  $( document ).scroll(function() {

    if($( document ).scrollTop()>=$('.layout-list .col-12').offset().top){
      let num = $( document ).scrollTop()  - $('.layout-list .col-12').offset().top;
      $('.cafe-detail').css('top',num)
    }
    else{
      $('.cafe-detail').css('top','0')  
    }

  });

}

}
*/
/*
if(pathname.includes('/cafe/')&&pathname!='/cafe/list/'){

  if($(window).width()>768){
  
  
    $( document ).scroll(function() {

      if($( document ).scrollTop()>=$('.cafe .container .col-12').offset().top){
       
        let num = $( document ).scrollTop()  - $('.cafe .container .col-12').offset().top - 30;
        $('.cafe-detail').css('top',num)
    
      }
      else{
           $('.cafe-detail').css('top','0')  
      }
  
    });
  }
  
  }*/
  document.addEventListener('DOMContentLoaded', function() {

  if(document.querySelectorAll('.block-tags .swiper-wrapper .swiper-slide')){
    let arr = document.querySelectorAll('.block-tags .swiper-wrapper .swiper-slide')
    if(arr.length>0){
      document.querySelector('.block-tags .swiper-wrapper ').style.overflowX='auto'
      document.querySelector('.block-tags .swiper-wrapper ').style.gap='16px'
    }
  }
  if(document.querySelector('.view-404')){

    (function(loc, ref) {
      ga('send', 'event', 'Ошибка 404', loc, ref, {
          'nonInteraction': 1
      });
      ga('set', 'metric1', 1);
  })(window.location.href, document.referrer);
  }

  })
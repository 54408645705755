window.MODAL_MIX = {
  data: () => ({
    isOpen: false,
  }),
  watch: {
    isOpen (open) {
      const action = open ? 'add' : 'remove'
      document.body.classLis[action]('is-custom-modal-active')
    }
  } 
}

window.MEDIA_MIX = {
  data: () => ({
    media: {
      isMobile: false, 
    }
  }),
  methods: {
    mediaMixCheck () {
      this.media.isMobile = matchMedia('(max-width: 739.8px)').matches
    },
    mediaMixInit () {
      window.addEventListener('resize', this.mediaMixCheck)
      this.mediaMixCheck()
    },
  }
}
